import { graphql, navigate } from "gatsby"
import React, { useRef } from "react"
import styled, { keyframes } from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { ContentWrapper, Flex, TextWrapper, Wrapper } from "../layout/reusables"
import { OutlineButton, MainButton } from "../styles/buttons"
import Fade from "react-reveal/Fade"
import { TiArrowUp } from "react-icons/ti"
import { colors } from "../styles/colors"
import { useWindowScroll } from "react-use"

export default function WePhealtStory({ data }) {
    const { storyData } = data
    const { title, photos } = storyData

    const titleRef = useRef()

    const { y } = useWindowScroll()
    const scrollTopVisible = y > 200

    const handleScrollToTop = () => {
        titleRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        })
    }

    return (
        <Layout dark>
            <SEO title={title} />
            <Wrapper>
                <ContentWrapper ref={titleRef} style={{ padding: "20vh 20px" }}>
                    <Flex direction="column" align="center">
                        <Grid>
                            <Image src={photos[0]} />
                            <Image src={photos[1]} />
                        </Grid>
                        <TextWrapper style={{ maxWidth: 650 }}>
                            <Fade>
                                <ArticleContainer>
                                    <Article
                                        dangerouslySetInnerHTML={{
                                            __html: storyData.text,
                                        }}
                                    />
                                </ArticleContainer>
                                <Flex justify="center">
                                    <OutlineButton
                                        dark
                                        // style={{ border: "none" }}
                                        onClick={() =>
                                            navigate("/we-phealt-that")
                                        }
                                    >
                                        Go back to all stories
                                    </OutlineButton>
                                </Flex>
                            </Fade>
                        </TextWrapper>
                    </Flex>
                </ContentWrapper>
                {scrollTopVisible && (
                    <Container onClick={() => handleScrollToTop()}>
                        <TiArrowUp
                            style={{ marginBottom: 0 }}
                            color={colors.yellow}
                            size={34}
                        />
                    </Container>
                )}
            </Wrapper>
        </Layout>
    )
}

export const phealStoryQuery = graphql`
    query($id: String!) {
        storyData: wePhealtThat(id: { eq: $id }) {
            title
            text
            photos
            approved
        }
    }
`

const enter = keyframes`
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`
const Container = styled.div`
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 98;
    bottom: 30px;
    right: 30px;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
    opacity: 0;
    transform: translateY(10px);

    animation: ${enter} 0.4s ease-out forwards;
    @media (max-width: 500px) {
        bottom: 20px;
        right: 10px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    height: 60vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15vh;
`
const TitleBlock = styled.div`
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -10%;
    padding: 25px 35px;
    z-index: 1;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.08);
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-bottom: 35px;
    width: 100%;
`
const Image = styled.img`
    width: 100%;
    height: 100%;
    max-height: 60vh;
    object-fit: cover;

    @media (max-width: 550px) {
        max-height: 400px;
    }
`
const ArticleContainer = styled.div`
    width: 100%;
    margin-bottom: 50px;
`
const Article = styled.div`
    font-size: 18px;
    line-height: 1.5;

    @media (max-width: 550px) {
        font-size: 16px;
    }

    div {
        margin-bottom: 50px;
        width: 100%;
        /* display: none; */
        img {
            width: 100%;
        }
    }
    .wp-block-image {
        figure {
            span {
                width: 100% !important;
            }
        }
    }
    p {
        line-height: 1.5;
        font-size: 18px;
        margin-bottom: 20px;
    }
    blockquote {
        p {
            font-size: 28px !important;
            font-style: italic;
            text-align: center;
        }
    }
`
